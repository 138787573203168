import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`An international project to produce a new geological map of Gondwana - a
successor to the de  Wit et al. map from1988 - has received the support of
Unesco and the IUGS under the name `}<strong parentName="p">{`The Gondwana Map Project– the geological map
and the tectonic evolution of Gondwana.`}</strong>{` It  has `}<strong parentName="p">{`IGCP Project No. 628.`}</strong>{` The
project starts in 2013, has a five year duration and is led by  Professor Renata
Schmitt at the University of Rio de Janiero. All those who wish to join with
Earthworks in contributing to this project are invited to contact Colin Reeves
directly. An excerpt  from the full project proposal follows... `}</p>
    <p>{`The project 'aims to update the Gondwana Map of de Wit with an approach of the
21st century.  Since 1988, the geological data for the regions concerned have
improved incredibly in the wake  of new geochronological laboratories and
investigative methodologies. Thorough airborne  geophysical reconnaissance has
been extended across most parts of the constituent continents.  A new GIS
data-base is planned, with a dynamic digital process that will allow the
construction  not just an improved Gondwana Map but also a wide variety of maps
showing the evolution of  this supercontinent. Geophysical advances at
continental margins and oceanic floors, the  modelling of the restoration with
new software and the analysis of satellite imagery permits  scientifically
rigorous reconstruction of Gondwana. The main products will be: (a) a new
`}<strong parentName="p">{`Gondwana Map`}</strong>{` and sets of thematic maps showing its evolution through time; (b) a
website providing to all the geological data taken into the project at the
`}<strong parentName="p">{`Gondwana Digital Center of Geoprocessing (GDCG);`}</strong>{` (c) three complete book volumes
about Gondwana; (d)  new detailed geology of key areas for correlation; (e) an
interactive 4-D GIS of Gondwana (f)  creation of a permanent exposition at the
`}<strong parentName="p">{`Gondwana Memory Center (GMC)`}</strong>{`, in South  America, with specimens representative
of all parts of Gondwana'. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      